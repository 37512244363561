import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {useState} from 'react';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

import axios from 'axios';
//import edusim from '../config/edusim';
import splash from './imgs/splash.jpg';

import logoImage from "./imgs/logo.png";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://empowered.cactisoft.com/">
        Cacti Software K12 Apps ™ 
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    /* backgroundImage: 'url(https://source.unsplash.com/random)', */
    backgroundImage: "url(" + splash + ")",
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  avatarProgress: {
    //color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  }
}));

export default function MainView(props) {
  const isMounted = React.useRef(null);
  const [activeComponent, setActiveComponent] = useState('mainView');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [signupValidationOn, setSignupValidationOn] = useState({validate: false, callback:null});
  const [realname,setRealname] = useState('');
  const [realnameError,setRealnameError] = useState({});
  const [susername,setSusername] = useState('');
  const [susernameError,setSusernameError] = useState({});
  const [mobile,setMobile] = useState('');
  const [mobileError,setMobileError] = useState({});
  const [termsChecked,setTermsChecked] = useState(false);
  const [termsCheckedError,setTermsCheckedError] = useState({});
 
  const classes = useStyles();

  const errorMessage = (
    error !== '' && <Alert severity="error">{error}</Alert>
  );

  const successMessage = (
    success !== '' && <Alert severity="success">{success}</Alert>
  );

  const apiUri = "https://cloudibs.com:8090";

  React.useEffect(()=>{
    
    setTimeout(()=>{
      if(isMounted.current){
        if(error !== ''){
          setError('');
        }
      }
    }, 3000);

    setTimeout(()=>{
      if(isMounted.current){
        if(success !== ''){
          setSuccess('');
        }
      }
    }, 6000);

    
  },[error, success]);

  React.useEffect(()=>{

    isMounted.current = true;

    const validateSignUpFields = () => {
      const hasNumber = value => {
        return new RegExp(/[0-9]/).test(value);
      }
      const hasMixed = value => {
          return new RegExp(/[a-z]/).test(value) &&
                  new RegExp(/[A-Z]/).test(value);
      }
      const validEmail = value => {
        return new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value);
      }
      const validPhone = (phone) => {
        var regex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
        return regex.test(phone);
      }

      let valid = true;

      if(!realname){
        valid = false;
        setRealnameError({error:true, message:'Required!'});
      }else if(realname.trim().length < 5){
        valid = false;
        setRealnameError({error:true, message:'Must be at least 5 characters!'});
      }else{
        setRealnameError({error:false, message:null});
      }

      if(!susername){
        valid = false;
        setSusernameError({error:true, message:'Required!'});
      }else if(!validEmail(susername)){
        valid = false;
        setSusernameError({error:true, message:'Must be a valid email address!'});
      }else{
        setSusernameError({error:false, message:null});
      }

      if(!mobile){
        valid = false;
        setMobileError({error:true, message:'Required!'});
      }else if(!validPhone(mobile)){
        valid = false;
        setMobileError({error:true, message:'Must be valid international phone number like +973********!'});
      }else{
        setMobileError({error:false, message:null});
      }

      if(!termsChecked){
        valid = false;
        setTermsCheckedError({error:true, message:'You must confirm this!'});
      }else{
        setTermsCheckedError({error:false, message:null});
      }
      
      if(signupValidationOn.callback)
        signupValidationOn.callback(valid);
    }

    if(signupValidationOn.validate)
      validateSignUpFields();

    return () => {
        // executed when unmount
        isMounted.current = false;
    }

  },[realname, susername, mobile, termsChecked, signupValidationOn]);
  
  const mainView = (
    <Grid container component="main" className={classes.root}>
    <CssBaseline />
    <Grid item xs={false} sm={4} md={7} className={classes.image} />
    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
      <div className={classes.paper}>
        <Box className={classes.title}><a href="https://empowered.cactisoft.com"><img width={300} src={logoImage} /></a></Box>
        {successMessage}
        {errorMessage}
        <div className={classes.wrapper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          {loading && <CircularProgress size={68} className={classes.avatarProgress} />}
        </div>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <form id="signupForm" className={classes.form} /* noValidate */>
          <TextField
              autoComplete="name"
              error={realnameError['error']? true: false}
              helperText={realnameError['message']?realnameError['message']: null}
              name="fullName"
              variant="outlined"
              required
              fullWidth
              id="fullName"
              label="Full Name"
              onChange={(e)=> setRealname(e.target.value)}
              onKeyPress={(e) => { signUpEnterKey(e);}}
              autoFocus
          />
          <TextField
            error={susernameError['error']? true: false}
            helperText={susernameError['message']?susernameError['message']: null}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            onChange={(e)=> setSusername(e.target.value)}
            onKeyPress={(e) => { signUpEnterKey(e);}}
          />
          <TextField
            error={mobileError['error']? true: false}
            helperText={mobileError['message']?mobileError['message']: null}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="mobile"
            label="Mobile"
            id="mobile"
            autoComplete="mobile"
            onChange={(e)=> setMobile(e.target.value)}
            onKeyPress={(e) => { signUpEnterKey(e);}}
          />
          <FormControl required error={termsCheckedError['error']? true: false} component="fieldset">
          <FormControlLabel
            control={
            <Checkbox 
            checked={termsChecked} 
            onChange={(e)=>setTermsChecked(e.target.checked)} 
            name="terms" 
            color="primary"
            />}
            label={<span style={{ 'fontSize': '1rem' }}>
              I hereby accept the <Link component="button" onClick={() => setActiveComponent('terms')} variant="body2">
                {"Terms and Conditions of Use"}
              </Link>. I also understand that by signing up, I might receive a verification code to my phone number by SMS. Message and data rates may apply accordingly.
            </span>}
          />
          <FormHelperText>{termsCheckedError['message']?termsCheckedError['message']: ''}</FormHelperText>
          </FormControl>
           
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={()=>signUpTenant()}
          >
            Sign Up
          </Button>
          {/* <Grid container>
            <Grid item xs>
              <Link component="button" onClick={() => setActiveComponent('forgotPass')} variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link component="button" onClick={() => setActiveComponent('signIn')} variant="body2">
                {"Sign In"}
              </Link>
            </Grid>
          </Grid> */}
          <Box mt={5}>
            <Copyright />
          </Box>
        </form>
      </div>
    </Grid>
  </Grid>
  );

  const termsAndConditions = (
  <Grid container component="main" className={classes.root}>
    <CssBaseline />
    <Grid item xs={false} sm={4} md={7} className={classes.image} />
    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Terms and Conditions of Use
        </Typography>

        <Typography>
          <p>
          By signing up to use our services, you agree implicitly to the following terms and conditions:
          </p>
          <ol>
            <li>
            You will use this Beta application as is for testing purposes with no implied warranties.
            </li>
            <li>
            You are a school administrator or owner. You attest ownership of the email address and mobile phone number you will provide during the signup process.
            </li>
            <li>
            You will need to provide a mobile phone number for means of communication and account verification. We might send you verification codes via SMS or personally call you to verify your identity and activate your account. Some data or messaging fees might be charged to you by your provider. You will not be able to commence the application process unless your mobile phone number has been verified.
            </li>
            <li>
            We will not share or disclose any information whatsoever related to your account with any third party. For your security and privacy, you should not share your account details with anyone.
            </li>
            <li>
            Our agents or affiliates will never ask you for your password or verification OTPs. However, they might ask for other information related to your account for verification purposes.
            </li>
          </ol>

        </Typography>
        <Grid container>
            <Grid item>
              <Link component="button" onClick={() => setActiveComponent('mainView')} variant="body2">
                {"Back to Sign Up"}
              </Link>
            </Grid>
          </Grid>
          <Box mt={5}>
            <Copyright />
          </Box>
      </div>
    </Grid>
  </Grid>
  );

  const signUpEnterKey = (e) =>{
    if (/* ev.ctrlKey && */ e.key === 'Enter') {      
      e.preventDefault();
      signUpTenant()
    }
  }

  function resetValues(){
    console.log("resetting values")
    let elem = document.getElementById("signupForm");
    elem.reset();
    setSignupValidationOn({validate: false, callback:null});
    setRealname('');
    setRealnameError({});
    setSusername('');
    setSusernameError({});
    setMobile('');
    setMobileError({});
    setTermsChecked(false);
    setTermsCheckedError({});
    
  }

  const signUpTenant = () =>{
    setSignupValidationOn({validate: true, callback: (valid)=>{
      if(!valid){
        setError('Please fix the required information before proceeding.');
        setSignupValidationOn({validate: true, callback:null});
      }else{
        setLoading(true);
        axios.post(apiUri + '/tenantSignup', 
          { realname: realname,
            username: susername, 
            mobile: mobile, 
          })
          .then(res => {
            setLoading(false);
            resetValues();
            setSuccess('Successfully Signed Up. We will notify you by email when your application setup is complete.');
          }).catch( (error) => {
            console.log(error);
            //alert(error);
            setLoading(false);
            let errmsg = ""
            if(error.response){
              errmsg = error.response.data.message;
            }else{
              errmsg = "Something went wrong while reaching the API service. Are you connected to the internet?";
            }
            setError(errmsg);
          });
      }
    }})
    
    //setTimeout(()=>{setLoading(false);setSuccess('Successfully Signed Up. Check your email for activation information.')}, 3000);
  };

  return (
  <div>
   {activeComponent === 'mainView' && mainView}
   {activeComponent === 'terms' && termsAndConditions}
  </div>
  );
}
