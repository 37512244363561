import React from 'react';
import './App.css';
import {useState} from 'react';
import MainView from "./MainView";
import { Router /* Link as ReachLink */ } from "@reach/router";
import { createTheme, ThemeProvider } from "@material-ui/core";

function App() {

  /* const [user, setUser] = useState(
    JSON.parse(localStorage.getItem('@user')) || {}
  ); */

  const defaultTheme = createTheme({direction: 'ltr', palette: {
    type: "light",
    primary: {
      light: "#5C755E",
      main: "#5C755E",
      dark: "#004d40"
    }
  }})
  const [currentTheme, setCurrentTheme] = useState(defaultTheme);

  /* const updateUserInformation = (user) =>{
    setUser(user);
    localStorage.setItem("@user", JSON.stringify(user));
  } */

  React.useEffect(() => {
    //alert('useEffect Called on mount');
  },[]);

  return (
    <div>
     <ThemeProvider theme={currentTheme}>
      <Router primary={false}>
          {/* <SignInView path="/forgotpassword/:_resettoken" updateUserInformation={updateUserInformation} /> */}
          <MainView path="/*"  />
      </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;